.Promotion {
    margin-top: 4rem;
    padding-left: 10%;
    padding-right: 10%;
}

.promoInfoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Montserrat";
    gap: 1rem;
}

.promoThumbnail {
    width: 100%;
    border-radius: 10px;
    opacity: 0.7;
}

.promoVideoContainer {
    margin-top: 2rem;
}