.latestArticlesHeaderContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.LatestArticles {
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 7rem;
    font-family: "Montserrat";
}

.articlesContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}

@media(max-width: 1028px) {
    * {
        margin: 0;
        padding: 0;
    }

    .articlesContainer {
        display: flex;
        flex-direction: column;
    }

    .latestArticleImage {
        width: 100%;
    }
}