.Newsfeed {
    margin-top: 7rem;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 7rem;
}

.newsfeedHeaderContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 2rem;
}

.newsfeedTextLight {
    font-family: "Montserrat";
    font-weight: 300;
}

.newsfeedTextStrong {
    font-family: "Montserrat";
    font-weight: 600;
}

.newsfeedImageContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 7rem;
}

.newsfeedImageContainer > img {
    width: 250px;
    height: 250px;
    object-fit: cover;
}

.newsfeedInfoContainer {
    display: flex;
    justify-content: space-around;
    font-family: "Montserrat";
}

.newsfeedIconContainer ion-icon {
    font-size: 47px;
    margin-bottom: 20px;
}

@media(max-width: 1028px) {
    .newsfeedImageContainer {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .newsfeedInfoContainer {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    .newsfeedImageContainer > img {
        width: 100%;
    }

    .newsfeedHeaderContainer {
        text-align: center;
    }
}