.LandingPage {
    height: calc(100vh - 80px);
    display: flex;
}

.leftSideContainer {
    width: 50%;
    height: 100%;
}

.rightSideContainer {
    width: 50%;
    height: 100%;
    color: #fff;
    background-color: #1a1a1a;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 4%;
}

.leftSideContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.landingHeaderContainer {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 2rem;
}

.landingPageButton {
    width: 30%;
    padding: 1rem;
    background-color: #377DFF;
    border: none;
    margin-top: 2rem;
    color: white;
    border-radius: 10px;
    cursor: pointer;
}

.landingPageButton:hover {
    opacity: 0.7;
}


@media(max-width: 1028px) {
    .LandingPage {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .leftSideContainer {
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .rightSideContainer {
        width: 100%;
        padding: 0;
        margin: 0;
        justify-content: center;
        align-items: center;
        
    }

    .landingHeaderContainer {
        font-size: 1.7rem;
        text-align: center;
    }
}