.Navbar {
    display: flex;
    justify-content: space-around;
    height: 80px;
    background-color: #fff;
}

.logoContainer {
    display: flex;
    align-items: center;
    font-family: "Montserrat";
    font-weight: 600;
}

.navLinksContainer {
    display: flex;
    justify-content: space-between;
    width: 40%;
    align-items: center;
}

.navIconsContainer {
    display: flex;
    width: 10%;
    justify-content: space-evenly;
    align-items: center;
}

.navLink {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.navIcon {
    list-style: none;
}

.navIcon ion-icon {
    font-size: 1.4rem;
    cursor: pointer;
}

.logoContainer ion-icon {
    display: none;
}

@media(max-width: 1028px) {
    .navLinksContainer {
        display: none;
    }

    .navIconsContainer {
        gap: 1.4rem
    }

    .logoContainer ion-icon {
        display: flex;
        position: absolute;
        left: 0;
        font-size: 27px;
        margin-left: 1rem;
    }
}