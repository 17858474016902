.CategoryItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.CategoryItem:hover {
    opacity: 0.7;
}

.categoryImage {
    height: 140px;
    width: 140px;
    border-radius: 50%;
}