.JustIn {
    padding-left: 10%;
    padding-bottom: 7%;
    padding-top: 1.7rem;
}

.justInCardContainer {
    display: flex;
    gap: 1rem;
    overflow: hidden;
    font-family: "Montserrat";
    font-weight: 300;
    overflow-x: auto;
}

.JustInHeaderContainer {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: larger;
    margin-bottom: 2rem;
}

