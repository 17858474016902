.TopFourCardItem {
    width: 100%;
    position: relative; 
}

.topFourImage {
    width: 100%;
    height: 700px;
    object-fit: cover;
}

.topFourContainer {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
    padding: 10px 0; 
    color: white;
}

.topFourTitle, .topFourText {
    margin: 0; 
    padding: 5px;
    font-family: "Montserrat";
    font-weight: 600;
}

.topFourTitle {
    font-size: 21px;
}

@media(max-width: 1028px) {
    .topFourImage {
        height: 500px;
    }
}
