.latestArticleImage {
    width: 400px;
    height: 300px;
    object-fit: cover;
}

@media(max-width: 1028px) {
    .latestArticleImage {
        width: 100%;
    }
}